import { boardEnums, classEnums, collegeEnums, currentResidenceEnums, domicileEnums, fatherStatusEnums, groupNameEnums, medicalIllnessEnums, motherOccupationEnums, referenceRelationEnums, salaryEnums, schoolEnums, statusEnums } from "./temp";

export const userInputs = [
  {
    fieldName: "name",
    id: 1,
    label: "User Name",
    type: "text",
    placeholder: "E.g: Jawwad",
  },
  {
    fieldName: "email_id",
    id: 2,
    label: "Email",
    type: "text",
    placeholder: "E.g: muhammadjawwad417@gmail.com",
  },
  {
    fieldName: "status",
    id: 3,
    label: "Status",
    type: "dropdown",
    options: [1, 0],
    placeholder: "E.g: 1 or 0",
  },
  {
    fieldName: "type",
    id: 4,
    label: "Type",
    type: "dropdown",
    options: [
      {
        key: "ECAT",
        value: "ECAT"
      },
      {
        key: "MCAT",
        value: "MCAT"
      },
      {
        key: "Entry Test",
        value: "ET"
      }
    ],
    placeholder: "Select",
  },
  {
    fieldName: "password",
    id: 5,
    label: "Password",
    type: "text",
    placeholder: "E.g: min: 6 characters",
  },
  {
    fieldName: "gender",
    id: 6,
    label: "Gender",
    type: "dropdown",
    options: ["", "Male", "Female"],
    placeholder: "Select",
  },
  {
    fieldName: "mobile_number",
    id: 7,
    label: "Contact",
    type: "number",
    placeholder: "E.g: 923.........",
  },
];

export const categoryInputs = [
  {
    fieldName: "category_name",
    id: 1,
    label: "Category Name",
    type: "text",
    placeholder: "E.g: Applied Mathematics",
  },
  {
    fieldName: "no_of_quiz",
    id: 2,
    label: "Number of Quiz",
    type: "number",
    placeholder: "E.g: 10",
  },
  {
    fieldName: "status",
    id: 3,
    label: "Status",
    type: "dropdown",
    options: [1, 0],
    placeholder: "E.g: 1 or 0",
  },
  {
    fieldName: "type",
    id: 4,
    label: "Type",
    type: "dropdown",
    options: [
      {
        key: "ECAT",
        value: "ECAT"
      },
      {
        key: "MCAT",
        value: "MCAT"
      },
      {
        key: "Entry Test",
        value: "ET"
      }
    ],
    placeholder: "Select",
  },
];

export const quizInputs = [
  {
    fieldName: "category_id",
    id: 1,
    label: "Category",
    type: "number",
    placeholder: "E.g: 2",
  },
  {
    fieldName: "quiz_no",
    id: 2,
    label: "Quiz Number",
    type: "text",
    placeholder: "E.g: Quiz 3",
  },
  {
    fieldName: "quiz_name",
    id: 3,
    label: "Quiz Name",
    type: "text",
    placeholder: "E.g: The Scientific Method",
  },
  {
    fieldName: "no_of_questions",
    id: 4,
    label: "Number of Questions",
    type: "number",
    placeholder: "E.g: 20",
  },
  {
    fieldName: "duration",
    id: 5,
    label: "Duration (Minutes)",
    type: "number",
    placeholder: "E.g: 5 (In Minutes)",
  },
  {
    fieldName: "description",
    id: 6,
    label: "Description",
    type: "text",
    placeholder: "E.g: Let's put your memory on our first topic to test.",
  },
  {
    fieldName: "no_of_attempts",
    id: 7,
    label: "No of Attempts",
    type: "dropdown",
    options: ["", "one", "infinite"],
    placeholder: "Select",
  },
  {
    fieldName: "status",
    id: 8,
    label: "Status",
    type: "dropdown",
    options: [1, 0],
    placeholder: "E.g: 1 or 0",
  },
];

export const questionInputs = [
  {
    fieldName: "quiz_id",
    id: 1,
    label: "Quiz",
    type: "number",
    placeholder: "E.g: 2",
  },
  {
    fieldName: "question",
    id: 2,
    label: "Question",
    type: "text",
    placeholder: "E.g: What is the smallest unit of life?",
  },
  {
    fieldName: "option_1",
    id: 3,
    label: "Option 1",
    type: "text",
    placeholder: "E.g: Cell",
  },
  {
    fieldName: "option_2",
    id: 4,
    label: "Option 2",
    type: "text",
    placeholder: "E.g: Atom",
  },
  {
    fieldName: "option_3",
    id: 5,
    label: "Option 3",
    type: "text",
    placeholder: "E.g: Molecule",
  },
  {
    fieldName: "option_4",
    id: 6,
    label: "Option 4",
    type: "text",
    placeholder: "E.g: Tissue",
  },
  {
    fieldName: "correct_option",
    id: 7,
    label: "Correct Option",
    type: "text",
    placeholder: "E.g: Cell",
  },
  {
    fieldName: "status",
    id: 8,
    label: "Status",
    type: "dropdown",
    options: [1, 0],
    placeholder: "E.g: 1 or 0",
  },
];

//new
export const personalInformationInputs = [
  {
    fieldName: "full_name",
    id: 1,
    label: "Student's Full Name",
    type: "text",
    placeholder: "E.g: Jawwad",
    isRequired:true
  },
  {
    fieldName: "b_form",
    id: 2,
    label: "CNIC/ B-Form Number",
    type: "text",
    placeholder: "E.g: 4250125517883",
    isRequired: true
  },
  {
    fieldName: "class",
    id: 3,
    label: "Class",
    type: "dropdown",
    options: classEnums,
    placeholder: "E.g: 'IX', 'X','XI','XII'",
    isRequired: true
  },
  {
    fieldName: "student_contact",
    id: 14,
    label: "Student's Contact Number",
    type: "text",
    placeholder: "E.g: +923XXXXXXXXX",
    isRequired: true
  },
  {
    fieldName: "address",
    id: 4,
    label: "Residential Address",
    type: "text",
    placeholder: "E.g: A-114 S#XXXXXX",
    isRequired: true
  },
  {
    fieldName: "domicile",
    id: 5,
    label: "Domicile (State/Province)",
    type: "dropdown",
    options: domicileEnums,
    placeholder: "E.g: Manager",
    isRequired: true
  },
  {
    fieldName: "medical_illness",
    id: 6,
    label: "Any Medical Illness",
    type: "dropdown",
    options: medicalIllnessEnums,
    placeholder: "E.g: Allergic",
    isRequired: true
  },
];

export const educationalInformationInputs = [
  {
    fieldName: "last_school_attended",
    id: 7,
    label: "Last School attended",
    type: "dropdown",
    options: schoolEnums,
    placeholder: "E.g: Qamar e Bani Hashim",
    isRequired: true
  },
  {
    fieldName: "last_school_attended",
    id: 8,
    label: "Last College attended",
    type: "dropdown",
    options: collegeEnums,
    placeholder: "E.g: Adamjee College",
    isRequired: true
  },
  {
    fieldName: "previous_education_board",
    id: 9,
    label: "Previous Board of Education",
    type: "dropdown",
    options: boardEnums,
    placeholder: "E.g: Karachi Board",
    isRequired: true
  },
  {
    fieldName: "percentage_last_class",
    id: 10,
    label: "Percentage in last class (Board Of Secondary Education)",
    type: "number",
    placeholder: "E.g: 90",
    isRequired: true
  },
  {
    fieldName: "percentage_preliminary_examination",
    id: 11,
    label: "Percentage in Preliminary Examinations (If Conducted)",
    type: "number",
    placeholder: "E.g: 96",
    isRequired: true
  },
  {
    fieldName: "group_name",
    id: 12,
    label: "Group you want to select",
    type: "dropdown",
    options: groupNameEnums,
    placeholder: "E.g: SCIENCE,MEDICAL,PRE-ENGINEERING,PRE-MEDICAL",
    isRequired: true
  },
];

export const familyInformationInputs = [
  {
    fieldName: "father_name",
    id: 13,
    label: "Father's Name",
    type: "text",
    placeholder: "E.g: Jawwad",
    isRequired: true
  },
  {
    fieldName: "father_status",
    id: 14,
    label: "Father's Status",
    type: "dropdown",
    options: fatherStatusEnums,
    placeholder: "E.g: ALIVE",
    isRequired: true
  },
  {
    fieldName: "father_contact",
    id: 15,
    label: "Father's/Guardian's Contact",
    type: "text",
    placeholder: "E.g: +923XXXXXXXXX",
    isRequired: true
  },
  {
    fieldName: "father_workplace",
    id: 16,
    label: "Father's Workplace (Company/Organizations/Institute etc)",
    type: "text",
    placeholder: "E.g: Texla",
    isRequired: true
  },
  {
    fieldName: "father_designation",
    id: 17,
    label: "Father's Designation in Company/Organisation/Institute",
    type: "text",
    placeholder: "E.g: Manager",
    isRequired: true
  },
  {
    fieldName: "mother_name",
    id: 18,
    label: "Mother's Name",
    type: "text",
    placeholder: "E.g: Zehra",
    isRequired: true
  },
  {
    fieldName: "mother_occupation",
    id: 19,
    label: "Mother's Occupation",
    type: "dropdown",
    options: motherOccupationEnums,
    placeholder: "E.g: House Wife",
    isRequired: true
  },
  {
    fieldName: "family_income",
    id: 20,
    label: "Family's Income in Rupees",
    type: "dropdown",
    options: salaryEnums,
    placeholder: "E.g: 40,000 - 50,000",
    isRequired: true
  },
  {
    fieldName: "siblings_count",
    id: 21,
    label: "Number of Siblings (Excluding the Candidate)",
    type: "number",
    placeholder: "E.g: 5",
    isRequired: true
  },
  {
    fieldName: "current_residence",
    id: 22,
    label: "Current Residence",
    type: "dropdown",
    options: currentResidenceEnums,
    placeholder: "E.g: Qamar e Bani Hashim",
    isRequired: true
  },
  {
    fieldName: "reference_name",
    id: 23,
    label: "Reference Name (With appropriate details of his/her reference to or batch of the Campus)",
    type: "text",
    placeholder: "E.g: Ali (E-12)",
    isRequired: false
  },
  {
    fieldName: "reference_contact",
    id: 24,
    label: "Reference Contact Number",
    type: "text",
    placeholder: "E.g: +923XXXXXXXXX",
    isRequired: false
  },
  {
    fieldName: "reference_relation",
    id: 25,
    label: "Relation with Reference",
    type: "dropdown",
    options: referenceRelationEnums,
    placeholder: "E.g: Ali (E-12)",
    isRequired: false
  },
  {
    fieldName: "status",
    id: 26,
    label: "Status",
    type: "dropdown",
    options: statusEnums,
    placeholder: "E.g: ACTIVE, INACTIVE, BLOCKED",
    isRequired: true
  },
  {
    fieldName: "year",
    id: 27,
    label: "Registration Year",
    type: "number",
    placeholder: "E.g: 2024",
    isRequired: true
  },
  {
    fieldName: "description",
    id: 28,
    label: "Note (Any)",
    type: "text",
    placeholder: "E.g: Note about student",
    isRequired: false
  },
];

// export const educationalDetailsInputs = [
 
// ];

//pervious
export const registrationInputs = [
  {
    fieldName: "full_name",
    id: 1,
    label: "Student's Full Name",
    type: "text",
    placeholder: "E.g: Jawwad",
  },
  {
    fieldName: "class",
    id: 2,
    label: "Class",
    type: "dropdown",
    options: classEnums,
    placeholder: "E.g: 'IX', 'X','XI','XII'",
  },
  {
    fieldName: "father_name",
    id: 3,
    label: "Father's Name",
    type: "text",
    placeholder: "E.g: Jawwad",
  },
  {
    fieldName: "b_form",
    id: 4,
    label: "CNIC/ B-Form Number",
    type: "text",
    placeholder: "E.g: 4250125517883",
  },
  {
    fieldName: "father_status",
    id: 5,
    label: "Father's Status",
    type: "dropdown",
    options: fatherStatusEnums,
    placeholder: "E.g: ALIVE",
  },
  {
    fieldName: "father_contact",
    id: 6,
    label: "Father's Contact Number",
    type: "text",
    placeholder: "E.g: +923XXXXXXXXX",
  },
  {
    fieldName: "father_workplace",
    id: 7,
    label: "Father's Workplace (Company/Organizations/Institute etc)",
    type: "text",
    placeholder: "E.g: Texla",
  },
  {
    fieldName: "father_designation",
    id: 8,
    label: "Father's Designation in Company/Organisation/Institute",
    type: "text",
    placeholder: "E.g: Manager",
  },
  {
    fieldName: "father_income",
    id: 9,
    label: "Father's Income in Rupees",
    type: "number",
    placeholder: "E.g: 100000",
  },
  {
    fieldName: "mother_name",
    id: 10,
    label: "Mother's Name",
    type: "text",
    placeholder: "E.g: Zehra",
  },
  {
    fieldName: "mother_workplace",
    id: 11,
    label: "Mother's Workplace (Company/Organizations/Institute etc)",
    type: "text",
    placeholder: "E.g: House Wife",
  },
  {
    fieldName: "mother_designation",
    id: 12,
    label: "Mother's Designation in Company/Organisation/Institute",
    type: "text",
    placeholder: "E.g: Manager",
  },
  {
    fieldName: "mother_income",
    id: 13,
    label: "Mother's Income in Rupees",
    type: "number",
    placeholder: "E.g: 0",
  },
  {
    fieldName: "student_contact",
    id: 14,
    label: "Student's Contact Number",
    type: "text",
    placeholder: "E.g: +923XXXXXXXXX",
  },
  {
    fieldName: "address",
    id: 15,
    label: "Residential Address",
    type: "text",
    placeholder: "E.g: A-114 S#XXXXXX",
  },
  {
    fieldName: "area",
    id: 16,
    label: "Area",
    type: "text",
    placeholder: "E.g: Jaffar Tayyar",
  },
  {
    fieldName: "domicile",
    id: 17,
    label: "Domicile (State/Province)",
    type: "dropdown",
    options: domicileEnums,
    placeholder: "E.g: Manager",
  },
  {
    fieldName: "last_school_attended",
    id: 18,
    label: "Last School attended",
    type: "dropdown",
    options: schoolEnums,
    placeholder: "E.g: Qamar e Bani Hashim",
  },
  {
    fieldName: "last_school_attended",
    id: 19,
    label: "Last College attended",
    type: "dropdown",
    options: collegeEnums,
    placeholder: "E.g: Adamjee College",
  },
  {
    fieldName: "previous_education_board",
    id: 20,
    label: "Previous Board of Education",
    type: "text",
    placeholder: "E.g: Karachi Board",
  },
  {
    fieldName: "percentage_last_class",
    id: 21,
    label: "Percentage in last class (Board Of Secondary Education)",
    type: "number",
    placeholder: "E.g: 90",
  },
  {
    fieldName: "percentage_preliminary_examination",
    id: 22,
    label: "Percentage in Preliminary Examinations (If Conducted)",
    type: "number",
    placeholder: "E.g: 96",
  },
  {
    fieldName: "group_name",
    id: 23,
    label: "Group you want to select",
    type: "dropdown",
    options: groupNameEnums,
    placeholder: "E.g: SCIENCE,MEDICAL,PRE-ENGINEERING,PRE-MEDICAL",
  },
  {
    fieldName: "siblings_count",
    id: 24,
    label: "Number of Siblings (Excluding the Candidate)",
    type: "number",
    placeholder: "E.g: 5",
  },
  {
    fieldName: "earning_siblings",
    id: 25,
    label: "Number of siblings who earn",
    type: "number",
    placeholder: "E.g: 2",
  },
  {
    fieldName: "current_residence",
    id: 26,
    label: "Current Residence",
    type: "dropdown",
    options: currentResidenceEnums,
    placeholder: "E.g: Qamar e Bani Hashim",
  },
  {
    fieldName: "reference_contact",
    id: 27,
    label: "Reference Contact Number",
    type: "text",
    placeholder: "E.g: +923XXXXXXXXX",
  },
  {
    fieldName: "reference_name",
    id: 28,
    label: "Reference Name (With appropriate details of his/her reference to or batch of the Campus)",
    type: "text",
    placeholder: "E.g: Ali (E-12)",
  },
  {
    fieldName: "reference_relation",
    id: 29,
    label: "Relation with Reference",
    type: "text",
    placeholder: "E.g: Ali (E-12)",
  },
  {
    fieldName: "medical_illness",
    id: 30,
    label: "Any Medical Illness",
    type: "dropdown",
    options: medicalIllnessEnums,
    placeholder: "E.g: Alergic",
  },
  {
    fieldName: "status",
    id: 31,
    label: "Status",
    type: "dropdown",
    options: statusEnums,
    placeholder: "E.g: ACTIVE, INACTIVE, BLOCKED",
  },
  {
    fieldName: "year",
    id: 32,
    label: "Registration Year",
    type: "number",
    placeholder: "E.g: 2024",
  },
  {
    fieldName: "description",
    id: 33,
    label: "Description",
    type: "text",
    placeholder: "E.g: Note about student",
  },
];
